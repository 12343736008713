/* Global Styles */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Oi&family=Roboto:wght@400;500;700&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  font-family: Arial, sans-serif;
  background-color: #000000;
}

/* Hero Banner Styles */
.hero-banner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 400px;
  border-radius: 0;
}

.hero-banner .content {
  flex: 1 0 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: visible;
  z-index: 5;
  position: relative;
  flex-wrap: nowrap;
  gap: 40px;
  border-radius: 0;
  padding: 0 50px;
  width: 100%;
}

.corner-emoji {
  position: absolute;
  font-size: 24px;
  z-index: 2;
}

.corner-emoji-top-left {
  top: 10px;
  left: 10px;
}

.corner-emoji-top-right {
  top: 10px;
  right: 10px;
}

.corner-emoji-bottom-left {
  bottom: 10px;
  left: 10px;
}

.corner-emoji-bottom-right {
  bottom: 10px;
  right: 10px;
}

.hero-banner .heading {
  font-weight: 400;
  font-style: normal;
  font-family: "Oi", cursive;
  color: #427aa1;
  font-size: 32px;
  text-align: center;
  margin: 0;
  line-height: 1.3;
}

@media screen and (min-width: 768px) {
  .hero-banner {
    height: 600px;
    justify-content: space-between;
  }

  .hero-banner .content {
    padding: 0 80px;
  }

  .hero-banner .heading {
    font-size: 42px;
    margin-top: 0;
  }
}

@media screen and (min-width: 1024px) {
  .hero-banner {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    height: 800px;
  }

  .hero-banner .content {
    align-items: center;
    gap: 20px;
  }

  .hero-banner .heading {
    font-size: 60px;
    margin-top: 0;
    width: 100%;
  }
}
/* Event Intro */

.event-intro-text h2 {
  margin-bottom: 10px;
  font-family: "Oi", cursive;
}

.event-intro-text p {
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
  color: #fff;
}

/* @media screen and (max-width: 786px) {
  .event-intro {
    flex-direction: column;
    margin-top: 40px;
  }

  .event-intro-image img {
    max-width: 100%;
    max-width: 300px;
    margin-bottom: 20px;
  }

  .event-intro-text {
    max-width: 100%;
    margin: 0;
  }
} */

/* Facilitator Card Styles */
.facilitator-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  margin: 10px;
  border-radius: 8px;
}

.facilitator-card img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 10px;
  aspect-ratio: 1/1;
}

.facilitator-card h3 {
  color: #ffffff;
}

/* Facilitators Section Styles */
.facilitators-section {
  padding: 40px;
  text-align: center;
}

.facilitators-section h2 {
  margin-bottom: 20px;
}

.facilitator-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

/* Footer Styles */
footer {
  background-color: #f5f5f5;
  padding-top: 20px;
  text-align: center;
}

.footer-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-content a {
  color: #333;
  margin: 0 10px;
  text-decoration: none;
}

.footer-content a:hover {
  text-decoration: underline;
}
